import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import { authGuard } from '../auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL, // eslint-disable-line
  routes: [
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      beforeEnter: authGuard,
    },
    {
      path: '*',
      component: Home,
    },
  ],
})

export default router
