<template>
  <div class="nav-container mb-3">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
      <div class="container">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul id="dropDownWrapper" v-if="$auth.isAuthenticated && !$auth.loading">
            <li class="dropdown">
              <a href="#" id="groupDropDown" class="dropdown-toggle" data-toggle="dropdown"
                >Groups<b class="caret"></b
              ></a>
              <ul class="dropdown-menu multi-column columns-3">
                <div class="row">
                  <div class="col-sm-4" v-for="col in items" :key="col">
                    <ul class="multi-column-dropdown">
                      <li v-for="item in col" :key="item">
                        <router-link :to="'/' + item.toLowerCase()">
                          {{ item.replace('Info-', 'Info') }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </ul>
            </li>
          </ul>

          <ul v-if="!$auth.isAuthenticated && !$auth.loading" class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Welcome</router-link>
            </li>
          </ul>
          <ul class="navbar-nav d-none d-md-block">
            <li v-if="!$auth.isAuthenticated && !$auth.loading" class="nav-item">
              <button id="qsLoginBtn" class="btn btn-primary btn-margin" @click.prevent="login">
                Login
              </button>
            </li>

            <li class="nav-item dropdown" v-if="$auth.isAuthenticated">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="profileDropDown"
                data-toggle="dropdown"
              >
                <img
                  :src="$auth.user.picture"
                  alt="User's profile picture"
                  class="nav-user-profile rounded-circle"
                  width="50"
                />
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-header">{{ $auth.user.name }}</div>
                <a id="qsLogoutBtn" href="#" class="dropdown-item" @click.prevent="logout">
                  <font-awesome-icon class="mr-3" icon="power-off" />Log out
                </a>
              </div>
            </li>
          </ul>

          <ul class="navbar-nav d-md-none" v-if="!$auth.isAuthenticated && !$auth.loading">
            <button id="qsLoginBtn" class="btn btn-primary btn-block" @click="login">Log in</button>
          </ul>

          <ul
            id="mobileAuthNavBar"
            class="navbar-nav d-md-none d-flex"
            v-if="$auth.isAuthenticated"
          >
            <li class="nav-item">
              <span class="user-info">
                <img
                  :src="$auth.user.picture"
                  alt="User's profile picture"
                  class="nav-user-profile d-inline-block rounded-circle mr-3"
                  width="50"
                />
                <h6 class="d-inline-block">{{ $auth.user.name }}</h6>
              </span>
            </li>
            <li>
              <font-awesome-icon icon="power-off" class="mr-3" />
              <a id="qsLogoutBtn" href="#" class @click.prevent="logout">Log out</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'NavBar',
  data: function () {
    return {
      ignored: [],
      items: [],
      currentPath: 'Admin',
    }
  },
  methods: {
    evenlySplitIntoColumns(data) {
      const maxRowsPerColumn = 6
      data.sort((a, b) => (a > b ? 1 : -1))
      return data
        .filter((item) => {
          return !this.ignored.includes(item)
        })
        .reduce((all, one, i) => {
          const ch = Math.floor(i / maxRowsPerColumn)
          all[ch] = [].concat(all[ch] || [], one)
          return all
        }, [])
        .map((col) =>
          col.map(
            (item) =>
              // remove .csv from the end of the item and capitalize the first letter
              item.charAt(0).toUpperCase() + item.slice(1).replace('.csv', '')
          )
        )
    },
    navLink(link) {
      let cleanedUpLink = link.replace(/\.csv/, '')
      cleanedUpLink = cleanedUpLink.charAt(0).toUpperCase() + cleanedUpLink.slice(1)
      return cleanedUpLink
    },
    login() {
      this.$auth.loginWithRedirect()
    },
    logout() {
      this.$auth.logout()
      this.$auth.token = ''
      this.$router.push({ path: '/' })
    },
  },
  async mounted() {
    this.$auth.$watch('loading', async () => {
      if (!this.$auth.token && this.$auth.isAuthenticated) {
        const claim = await this.$auth.getIdTokenClaims()
        this.$auth.token = claim.__raw
      }

      if (this.$auth.isAuthenticated) {
        axios
          .get('/api/ignored-groups', {
            headers: {
              Authorization: 'Bearer ' + this.$auth.token, // the token is a variable which holds the token
            },
          })
          .then(({ data }) => {
            this.ignored = data
          })
          .catch((err) => {
            throw err
          })
          .then(() => {
            axios
              .get('/api/getListOfFiles', {
                headers: {
                  Authorization: 'Bearer ' + this.$auth.token, // the token is a variable which holds the token
                },
              })
              .then(({ data }) => (this.items = this.evenlySplitIntoColumns(data)))
              .catch((err) => {
                throw err
              })
          })
      }
    })
  },
}
</script>

<style>
html,
body {
  height: 100%;
}

html {
  margin: auto;
}

body {
  vertical-align: middle;
}

.container {
  margin-left: auto;
  padding-left: auto;
}

#mobileAuthNavBar {
  min-height: 125px;
  justify-content: space-between;
}

#navbarNav {
  justify-content: space-between;
}

#dropDownWrapper {
  list-style: none;
  padding-left: 0px;
}

#groupDropDown {
  font-size: 20px;
}

.dropdown-menu {
  min-width: 200px;
}

.dropdown-menu.columns-3 {
  min-width: 1200px;
}

.dropdown-menu li a {
  padding: 5px 15px;
  font-weight: 300;
}

.multi-column-dropdown {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.multi-column-dropdown li a {
  display: block;
  clear: both;
  line-height: 1.5;
  color: #333;
  white-space: normal;
}

.multi-column-dropdown li a:hover {
  text-decoration: none;
  color: #262626;
  background: #999;
}

@media (max-width: 767px) {
  .dropdown-menu.multi-column {
    min-width: 240px !important;
    overflow-x: hidden;
  }
}
</style>
