<template>
  <div class="text-center hero">
    <h1 v-if="$auth.isAuthenticated && !$auth.loading">
      {{ navLink(currentPath).replace('Info-', 'Info') }}
    </h1>
    <div>
      <b-table
        v-if="$auth.isAuthenticated && !$auth.loading"
        striped
        hover
        :items="items"
      ></b-table>
      <p v-if="currentPath === '' && $auth.isAuthenticated && !$auth.loading">
        Welcome to TZ Medical's Distribution please choose from an item in the list above
      </p>
      <p v-if="!$auth.isAuthenticated && !$auth.loading">
        Please login to view all tracked TZM Distribution Groups!
      </p>
      <p v-if="currentPath !== '' && items.length === 0 && !$auth.loading && $auth.isAuthenticated">
        No users belong to the {{ navLink(currentPath) }} group
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Group',
  data() {
    return {
      items: [],
    }
  },
  computed: {
    currentPath: function () {
      return this.$route.path.replace(/\//, '')
    },
  },
  methods: {
    navLink(link) {
      let cleanedUpLink = link.replace(/\.csv/, '')
      cleanedUpLink = cleanedUpLink.charAt(0).toUpperCase() + cleanedUpLink.slice(1)
      return cleanedUpLink
    },
    marshall(items) {
      this.items = items.data.map((item) => {
        delete item.group
        delete item.id
        delete item.role
        delete item.type
        delete item.status
        delete item.etag
        delete item.kind

        return item
      })
    },
    getFile() {
      if (this.currentPath !== '') {
        axios
          .get(`/api/getFile?file=${this.currentPath}`, {
            headers: {
              Authorization: 'Bearer ' + this.$auth.token, //the token is a variable which holds the token
            },
          })
          .then(this.marshall)
          .catch((err) => {
            throw err
          })
      }
    },
  },
  async mounted() {
    this.$auth.$watch('token', async () => {
      if (this.$auth.token) {
        this.getFile()
      }
    })
  },
  watch: {
    currentPath: function () {
      this.getFile()
    },
  },
}
</script>
