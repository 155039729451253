<template>
  <div id="app" class="d-flex flex-column h-100">
    <nav-bar />
    <div class="container flex-grow-1">
      <error />
      <div>
        <router-view />
      </div>
    </div>
    <footer class="bg-light text-center p-3">
      <p>
        Distribution Lists provided by
        <a href="https://tzmedical.com">TZ Medical Inc</a>
      </p>
    </footer>
  </div>
</template>

<script>
import NavBar from './components/NavBar'
import Error from './components/Error'

export default {
  components: {
    NavBar,
    Error,
  },
}
</script>
